import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { zodUndefinedIfInvalid } from '@digital-magic/react-common/lib/utils/zod'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { routes } from '@constants/routes'
import { formatDateShort } from '@utils/date-utils'
import { RequestErrorHandler } from '@api/types'
import { BookingId, BookingListItem, BookingNumber, BookingState } from '@api/endpoints/bookings'
import { useConfirmBooking, useDeleteBooking, useGetBookings, useRestoreBooking } from '@api/endpoints/bookings/api'
import { HouseStatus } from '@api/endpoints/buildings/houses'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useDebounce } from '@hooks/useDebounce'
import { useSnackbar } from 'notistack'
import { Check, Clear, RestoreFromTrash } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Form, FormTextField, useFormTyped } from '@controls/Form'
import { Table, TableColumn } from '@controls/Table'
import { BookingsStyled } from './Bookings.style'
import { isBookingUnavailable } from './utils'

const BookingSearchValues = z.object({
  bookingNumber: BookingNumber.optional()
})

export const Bookings: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const snackbar = useSnackbar()

  const onDeleteBookingError: RequestErrorHandler = (e) => {
    if (isApiError(e)) {
      switch (e.code) {
        case 'NotFoundError':
          snackbar.enqueueSnackbar(t('pages.admin.booking.errors.not_found'))
          break
        default:
          defaultErrorHandler(e)
          break
      }
    }
  }

  const onConfirmBookingError: RequestErrorHandler = (e) => {
    if (isApiError(e)) {
      switch (e.code) {
        case 'NotFoundError':
          snackbar.enqueueSnackbar(t('pages.admin.booking.errors.not_found'))
          break
        default:
          defaultErrorHandler(e)
          break
      }
    }
  }

  const onRestoreBookingError: RequestErrorHandler = (e) => {
    if (isApiError(e)) {
      switch (e.code) {
        case 'NotFoundError':
          snackbar.enqueueSnackbar(t('pages.admin.booking.errors.not_found'))
          break
        default:
          defaultErrorHandler(e)
          break
      }
    }
  }

  const form = useFormTyped({
    resolver: BookingSearchValues,
    onSubmit: () => {
      return false
    },
    mode: 'all'
  })

  const bookingNumber = form.watch('bookingNumber')
  const debouncedBookingNumber = useDebounce(bookingNumber, 500)
  const validBookingNumber = zodUndefinedIfInvalid(debouncedBookingNumber, BookingNumber)

  const bookingState: OptionalType<BookingState> = undefined

  const deleteBooking = useDeleteBooking({ onError: onDeleteBookingError })
  const confirmBooking = useConfirmBooking({ onError: onConfirmBookingError })
  const restoreBooking = useRestoreBooking({ onError: onRestoreBookingError })
  const getBookings = useGetBookings(validBookingNumber, bookingState, { onError: defaultErrorHandler })

  const bookings = React.useMemo(() => getBookings.data ?? [], [getBookings.data])

  const loading =
    getBookings.isFetching || deleteBooking.isLoading || confirmBooking.isLoading || restoreBooking.isLoading

  const onRowClick = (row: BookingListItem): void => {
    navigate(generatePath(routes.Admin.BookingsEdit, { bookingId: row.id }))
  }

  const onConfirmBooking =
    (id: BookingId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        confirmBooking.mutate({ id, status: HouseStatus.enum.Booked })
      }
    }

  const onDeleteBooking =
    (id: BookingId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteBooking.mutate(id)
      }
    }

  const onRestoreBooking =
    (id: BookingId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        restoreBooking.mutate(id)
      }
    }

  const tableColumns: ReadonlyArray<TableColumn<BookingListItem>> = React.useMemo(
    () => [
      {
        key: 'bookingNumber',
        title: t('pages.admin.bookings.fields.booking_number'),
        sortable: true
      },
      {
        key: 'address',
        title: t('pages.admin.bookings.fields.address'),
        sortable: true,
        render: (row) => `${row.address}${row.apartmentNumber ? ' - ' + row.apartmentNumber : ''}`
      },
      {
        key: 'customerEmail',
        title: t('pages.admin.bookings.fields.customer_email'),
        sortable: false,
        render: (row) => row.customerEmail
      },
      {
        key: 'totalPrice',
        title: t('pages.admin.bookings.fields.total_price'),
        sortable: false
      },
      {
        key: 'status',
        title: t('pages.admin.bookings.fields.house_status'),
        sortable: true,
        render: (row) => t(`enums.booking_status.${row.status.toLowerCase()}`)
      },
      {
        key: 'state',
        title: t('pages.admin.bookings.fields.booking_status'),
        sortable: true,
        render: (row) => t(`enums.booking_state.${row.state.toLowerCase()}`)
      },
      {
        key: 'createdAt',
        title: t('pages.admin.bookings.fields.created'),
        sortable: true,
        render: (row) => formatDateShort(row.createdAt)
      },
      {
        key: null,
        title: null,
        render: (row) => (
          <Box display="flex" columnGap={1}>
            {!isBookingUnavailable(row.state) && (
              <ButtonWithConfirmation
                color="success"
                size="small"
                disabled={loading || row.status !== HouseStatus.enum.Available}
                onConfirmResult={onConfirmBooking(row.id)}
                confirmTitle={t('pages.admin.bookings.consent.confirm_booking.title')}
                confirmMessage={t('pages.admin.bookings.consent.confirm_booking.message')}
              >
                <Check />
              </ButtonWithConfirmation>
            )}
            {isBookingUnavailable(row.state) && (
              <ButtonWithConfirmation
                color="success"
                size="small"
                disabled={loading}
                onConfirmResult={onRestoreBooking(row.id)}
                confirmTitle={t('pages.admin.bookings.consent.restore_booking.title')}
                confirmMessage={t('pages.admin.bookings.consent.restore_booking.message')}
              >
                <RestoreFromTrash />
              </ButtonWithConfirmation>
            )}
            {!isBookingUnavailable(row.state) && (
              <ButtonWithConfirmation
                color="error"
                size="small"
                disabled={loading}
                onConfirmResult={onDeleteBooking(row.id)}
                confirmTitle={t('global.consent.delete.title')}
                confirmMessage={t('global.consent.delete.message')}
              >
                <Clear />
              </ButtonWithConfirmation>
            )}
          </Box>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  )

  return (
    <BookingsStyled>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h1>{t('pages.admin.bookings.title')}</h1>
        <Form f={form}>
          <FormTextField
            name={form.names.bookingNumber}
            label={t('pages.admin.bookings.search.by_booking_number.title')}
          />
        </Form>
      </Box>
      <Table
        columns={tableColumns}
        data={bookings}
        onRowClick={onRowClick}
        rowClassName={(row) => (isBookingUnavailable(row.state) ? 'deleted-row' : undefined)}
        initialSortConfig={{ column: 'createdAt', direction: 'DESC' }}
      />
      <Box display="flex" columnGap={2} mt={2}>
        <Button onClick={() => getBookings.refetch()}>{t('global.buttons.refresh')}</Button>
      </Box>
    </BookingsStyled>
  )
}
